import { CurrentUser, Cookies } from 'Roblox';
import UrlConfig from '../../../core/http/interfaces/UrlConfig';
import { getCryptoKeyPair, putCryptoKeyPair } from '../store/indexedDB';
import cryptoUtil from '../crypto/cryptoUtil';
import { getHbaMeta } from '../constants/hbaMeta';
import { sendBATMissingEvent, sendBATSuccessEvent } from '../utils/eventUtil';

const SEPARATOR = '|';
const allowedHosts = ['.roblox.com', '.robloxlabs.com', '.roblox.qq.com'];

let clientCryptoKeyPair: CryptoKeyPair;

type TbatWhiteListedApiObj = {
  apiSite: string;
  sampleRate: string;
};

type TbatExemptListedApiObj = {
  apiSite: string;
};

type TbatWhiteListObj = {
  Whitelist: TbatWhiteListedApiObj[];
};

type TbatExemptListObj = {
  Exemptlist: TbatExemptListedApiObj[];
};

const hbaMeta = getHbaMeta();

const {
  isBoundAuthTokenEnabled,
  boundAuthTokenWhitelist: batWhiteListStr,
  boundAuthTokenExemptlist: batExemptListStr,
  hbaIndexedDBName,
  hbaIndexedDBObjStoreName,
  hbaIndexedDBKeyName,
  hbaIndexedDBVersion
} = hbaMeta;

let batWhiteListArr: TbatWhiteListedApiObj[];
try {
  const batWhiteListObj = JSON.parse(batWhiteListStr) as TbatWhiteListObj;
  batWhiteListArr = batWhiteListObj.Whitelist;
} catch {
  batWhiteListArr = [];
}

let batExemptListArr: TbatExemptListedApiObj[];
try {
  const batExmeptListObj = JSON.parse(batExemptListStr) as TbatExemptListObj;
  batExemptListArr = batExmeptListObj.Exemptlist;
} catch {
  batExemptListArr = [];
}

const isUrlIncludedInBatWhiteList = (url: string): boolean => {
  return (
    batWhiteListArr.length > 0 &&
    batWhiteListArr.some(ele => {
      return url.includes(ele.apiSite) && Math.floor(Math.random() * 100) < Number(ele.sampleRate);
    })
  );
};

const isUrlIncludeInExemptList = (url: string): boolean => {
  return (
    batExemptListArr.length > 0 &&
    batExemptListArr.some(ele => {
      return url.includes(ele.apiSite);
    })
  );
};

const isUrlInBATRequiredList = (url: string): boolean => {
  // TODO: manage this list in a setting
  return url.includes('/account-switcher/v1/switch');
};

const getHost = (urlStr: string): string => {
  try {
    // URL has been polyfilled for IE
    const loc = new URL(urlStr);
    return loc.hostname;
  } catch (e) {
    return '';
  }
};
// intentionally keep the urlConfig instead of url string as param for browser debugging purpose
const isUrlFromAllowedHost = (urlConfig: UrlConfig): boolean => {
  for (let i = 0; i < allowedHosts.length; i++) {
    if (getHost(urlConfig.url).endsWith(allowedHosts[i])) {
      return true;
    }
  }
  return false;
};

/**
 * Check if a request should attach bound auth token
 *
 * @param {UrlConfig} urlConfig
 * @returns a boolean
 */
export function shouldRequestWithBoundAuthToken(urlConfig: UrlConfig): boolean {
  try {
    /*
    Allow BAT header for the request when
    1. url is in the BATRequiredList
    OR
    all the following are met
      1. user is authenticated
      2. request has cookies
      3. url is from allowed host
      4. indexedDB is enabled
      5. url is NOT in exemptlisted url for BAT
      6. bat is enabled for all or url is in the whitelisted url for BAT
    */
    const hit =
      isUrlInBATRequiredList(urlConfig.url) ||
      (CurrentUser.isAuthenticated &&
        urlConfig.withCredentials &&
        isUrlFromAllowedHost(urlConfig) &&
        hbaIndexedDBName &&
        hbaIndexedDBObjStoreName &&
        hbaIndexedDBKeyName &&
        !isUrlIncludeInExemptList(urlConfig.url) &&
        (isBoundAuthTokenEnabled || isUrlIncludedInBatWhiteList(urlConfig.url)));
    return hit;
  } catch {
    return false;
  }
}

async function updateKeyForCryptoKeyPair(): Promise<CryptoKeyPair> {
  const browserTrackerId = Cookies.getBrowserTrackerId() || '';
  let keyPair = await getCryptoKeyPair(
    hbaIndexedDBName,
    hbaIndexedDBObjStoreName,
    browserTrackerId
  );
  if (keyPair && hbaIndexedDBKeyName) {
    await putCryptoKeyPair(
      hbaIndexedDBName,
      hbaIndexedDBObjStoreName,
      hbaIndexedDBKeyName,
      keyPair
    );
    keyPair = await getCryptoKeyPair(
      hbaIndexedDBName,
      hbaIndexedDBObjStoreName,
      hbaIndexedDBKeyName
    );
  }
  return keyPair;
}

/**
 * Generate a bound auth token
 *
 * @param {UrlConfig} urlConfig
 * @returns a bound auth token
 */
export async function generateBoundAuthToken(urlConfig: UrlConfig): Promise<string> {
  try {
    // step 1 get the key pair from indexedDB with key
    if (!clientCryptoKeyPair) {
      try {
        clientCryptoKeyPair = await getCryptoKeyPair(
          hbaIndexedDBName,
          hbaIndexedDBObjStoreName,
          hbaIndexedDBKeyName
        );
        // only updateKey if keyPair can't be find via hbaIndexedDBkeyName.
        if (!clientCryptoKeyPair) {
          clientCryptoKeyPair = await updateKeyForCryptoKeyPair();
        }
      } catch (e) {
        // don't block main thread if getCryptoKeyPair is rejected.
        return '';
      }
    }
    // if no key is found, return empty.
    // NOTE: this could be caused by IXP returning false from login/signup upstream
    // while the feature setting is on. BAT will only be available after SAI is enabled and key pairs are generated.
    if (!clientCryptoKeyPair) {
      return '';
    }

    // step 2 get the timeStamp
    const clientEpochTimestamp = Math.floor(Date.now() / 1000).toString();

    // step 3 hash request body
    let strToHash;
    if (typeof urlConfig.data === 'object') {
      strToHash = JSON.stringify(urlConfig.data);
    } else if (typeof urlConfig.data === 'string') {
      strToHash = urlConfig.data;
    }

    const hashedRequestBody = await cryptoUtil.hashStringWithSha256(strToHash);

    // step 4 payload to sign
    const payloadToSign = [hashedRequestBody, clientEpochTimestamp].join(SEPARATOR);

    // step 5 generate BAT signature
    const batSignature = await cryptoUtil.sign(clientCryptoKeyPair.privateKey, payloadToSign);

    return [hashedRequestBody, clientEpochTimestamp, batSignature].join(SEPARATOR);
  } catch (e) {
    console.warn('bat generation error: ', e);
    return '';
  }
}

/**
 * Build a urlconfig with Bound Auth Token
 *
 * @param {UrlConfig} urlConfig
 * @returns a urlConfig with bound auth token attached in the header
 */
export async function buildConfigBoundAuthToken(urlConfig: UrlConfig): Promise<UrlConfig> {
  if (!shouldRequestWithBoundAuthToken(urlConfig)) {
    return urlConfig;
  }
  // step 1 call generateBoundAuthToken
  const batString = await generateBoundAuthToken(urlConfig);

  // step 2 attach it to the header of the request
  const config = { ...urlConfig };
  if (batString) {
    config.headers = {
      ...config.headers,
      'x-bound-auth-token': batString
    };
    sendBATSuccessEvent(urlConfig.url);
  } else {
    sendBATMissingEvent(urlConfig.url);
  }

  return config;
}

export default {
  shouldRequestWithBoundAuthToken,
  generateBoundAuthToken,
  buildConfigBoundAuthToken
};
