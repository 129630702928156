import { ExperimentationService } from 'Roblox';
import cryptoUtil from '../crypto/cryptoUtil';
import { getCryptoKeyPair } from '../store/indexedDB';
import { getServerNonce } from '../services/hbaService';
import { hbaExperimentLayer, experimentVariables } from '../constants/ixpConstants';
import { storeClientKeyPair } from '../utils/storeUtil';
import { sendSAISuccessEvent, sendSAIMissingEvent } from '../utils/eventUtil';
import { TSecureAuthIntent } from '../types/hbaTypes';
import { getHbaMeta } from '../constants/hbaMeta';

const hbaMeta = getHbaMeta();

const {
  hbaIndexedDBName,
  hbaIndexedDBObjStoreName,
  hbaIndexedDBKeyName,
  isSecureAuthenticationIntentEnabled
} = hbaMeta;

const SEPARATOR = '|';

/**
 * Build signup & login request with SecureAuthIntent
 *
 * @returns an auth request parameter
 */
export const generateSecureAuthIntent = async (): Promise<TSecureAuthIntent> => {
  if (!isSecureAuthenticationIntentEnabled) {
    return null;
  }

  // get ixp variable from hba layer
  let isHBADarkLaunchEnabledOnIXP = false;
  try {
    const ixpResult = await ExperimentationService?.getAllValuesForLayer(hbaExperimentLayer);
    isHBADarkLaunchEnabledOnIXP = ixpResult[experimentVariables.isHBADarkLaunchEnabled] as boolean;
  } catch (e) {
    console.warn('ixp is unavailable');
  }

  if (!isHBADarkLaunchEnabledOnIXP) {
    sendSAIMissingEvent();
    return null;
  }

  try {
    // prerequisite: get serverNonce
    const serverNonce = await getServerNonce();
    if (!serverNonce) {
      // eslint-disable-next-line no-console
      console.warn('No hba server nonce available.');
      sendSAIMissingEvent();
      return null;
    }
    // step 1 try to get or generate clientKeyPair
    let clientKeyPair = {} as CryptoKeyPair;
    if (hbaIndexedDBName && hbaIndexedDBObjStoreName && hbaIndexedDBKeyName) {
      try {
        clientKeyPair = await getCryptoKeyPair(
          hbaIndexedDBName,
          hbaIndexedDBObjStoreName,
          hbaIndexedDBKeyName
        );
      } catch {
        // return empty keyPair upon exception
        clientKeyPair = {} as CryptoKeyPair;
      }
    }
    if (!clientKeyPair || Object.keys(clientKeyPair).length === 0) {
      clientKeyPair = await cryptoUtil.generateSigningKeyPairUnextractable();
      await storeClientKeyPair(clientKeyPair);
      clientKeyPair = await getCryptoKeyPair(
        hbaIndexedDBName,
        hbaIndexedDBObjStoreName,
        hbaIndexedDBKeyName
      );
    }
    // step 2 sign the payload with client private key.
    const clientPublicKey = await cryptoUtil.exportPublicKeyAsSpki(clientKeyPair.publicKey);
    const clientEpochTimestamp = Math.floor(Date.now() / 1000);
    const payload = [clientPublicKey, clientEpochTimestamp, serverNonce].join(SEPARATOR);

    const saiSignature = await cryptoUtil.sign(clientKeyPair.privateKey, payload);
    const secureAuthIntent = {
      clientPublicKey,
      clientEpochTimestamp,
      serverNonce,
      saiSignature
    };
    // step 3 send event and return
    sendSAISuccessEvent();
    return secureAuthIntent;
  } catch {
    sendSAIMissingEvent();
    return null;
  }
};

export default {
  generateSecureAuthIntent
};
