import eventStreamService from '../../../core/services/eventStreamService/eventStreamService';
import EVENT_CONSTANTS from '../constants/eventConstants';

const { batMissingEventSent, batSuccessEventSent } = EVENT_CONSTANTS.sessionStorageState;
export const sendBATSuccessEvent = (url: string): void => {
  if (!window.sessionStorage.getItem(batSuccessEventSent)) {
    window.sessionStorage.setItem(batSuccessEventSent, 'true');
    eventStreamService.sendEventWithTarget(
      EVENT_CONSTANTS.eventName.batCreated,
      EVENT_CONSTANTS.context.hba,
      {
        field: url
      }
    );
  }
};

export const sendBATMissingEvent = (url: string): void => {
  if (!window.sessionStorage.getItem(batMissingEventSent)) {
    window.sessionStorage.setItem(batMissingEventSent, 'true');
    eventStreamService.sendEventWithTarget(
      EVENT_CONSTANTS.eventName.batMissing,
      EVENT_CONSTANTS.context.hba,
      {
        field: url
      }
    );
  }
};

export const sendSAISuccessEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    EVENT_CONSTANTS.eventName.saiCreated,
    EVENT_CONSTANTS.context.hba,
    {}
  );
};

export const sendSAIMissingEvent = (): void => {
  eventStreamService.sendEventWithTarget(
    EVENT_CONSTANTS.eventName.saiMissing,
    EVENT_CONSTANTS.context.hba,
    {}
  );
};

export default {
  sendBATSuccessEvent,
  sendBATMissingEvent,
  sendSAISuccessEvent,
  sendSAIMissingEvent
};
